import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/practice-home',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./auth/login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'practice-home',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./practice-home/practice-home.module').then(
        (m) => m.PracticeHomePageModule
      ),
  },
  {
    path: 'patients',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./patients/patients.module').then((m) => m.PatientsPageModule),
  },
  {
    path: 'claim',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modals/claim/claim.module').then((m) => m.ClaimPageModule),
  },

  {
    path: 'add-patients',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modals/add-patients/add-patients.module').then(
        (m) => m.AddPatientsPageModule
      ),
  },

  {
    path: 'navigator',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./navigator/navigator.module').then((m) => m.NavigatorPageModule),
  },
  {
    path: 'admin',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminPageModule),
  },
  {
    path: 'add-practice',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modals/add-practice/add-practice.module').then(
        (m) => m.AddPracticePageModule
      ),
  },
  {
    path: 'invoice-practice',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modals/invoice-practice/invoice-practice.module').then(
        (m) => m.InvoicePracticePageModule
      ),
  },
  {
    path: 'notes',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modals/notes/notes.module').then((m) => m.NotesPageModule),
  },
  {
    path: 'edit-user',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modals/edit-user/edit-user.module').then(
        (m) => m.EditUserPageModule
      ),
  },
  {
    path: 'reports',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./reports/reports.module').then((m) => m.ReportsPageModule),
  },
  {
    path: 'prescription-meds',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modals/prescription-meds/prescription-meds.module').then(
        (m) => m.PrescriptionMedsPageModule
      ),
  },
  {
    path: 'gpcontracts',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modals/gpcontracts/gpcontracts.module').then(
        (m) => m.GpcontractsPageModule
      ),
  },
  {
    path: 'benefits',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modals/benefits/benefits.module').then(
        (m) => m.BenefitsPageModule
      ),
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
