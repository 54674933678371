import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { createState, Store, withProps, select } from '@ngneat/elf';

import { persistState, localStorageStrategy } from '@ngneat/elf-persist-state';
import { SupabaseClient } from '@supabase/supabase-js';
import { SupabaseService } from './supabase.service';

const { state, config } = createState(
  withProps<TenantState>({ tenants: [], activeTenant: null })
);
const tenantStore = new Store({ name: 'tenants', state, config });

persistState(tenantStore, {
  key: 'tenants',
  storage: localStorageStrategy,
});
@Injectable({
  providedIn: 'root',
})
export class StateService {
  client: SupabaseClient;
  tenants$ = tenantStore.pipe(select((s) => s.tenants));
  activeTenant$ = tenantStore.pipe(select((s) => s.activeTenant));
  tenantState$ = tenantStore.pipe(select((s) => s));

  constructor(private supabase: SupabaseService, private titleService: Title) {
    this.client = this.supabase.supabase;
  }

  async getTenant() {
    try {
      const { data } = await this.client
        .from('tenants')
        .select('*, switch(dataset, sourceId, password, live)');

      if (data.length > 0) {
        const currentState = this.getStateOnce();

        if (
          currentState.tenants.length === 0 &&
          currentState.activeTenant === null
        ) {
          this.setInitialTenantState({
            tenants: data,
            activeTenant: data[0],
          });
        }

        if (currentState.tenants !== data) {
          this.updateTenants(data);
        }

        if (currentState.activeTenant) {
          this.titleService.setTitle(currentState.activeTenant.name);
        }

        return currentState.activeTenant !== null
          ? currentState.activeTenant
          : data[0];
      }
    } catch (error) {
      throw error;
    }
  }

  /** Tenant State Methods*/

  getStateOnce(): TenantState {
    return tenantStore.getValue();
  }
  //set the initial state of the tenantStore
  setInitialTenantState(s: TenantState) {
    tenantStore.update((existingState) => ({
      ...s,
    }));

    //update the title
    document.title = s.activeTenant.name;
  }
  /** update the activeTenant */
  updateActiveTenant(tenant: Tenant) {
    tenantStore.update((existingState) => ({
      ...existingState,
      activeTenant: tenant,
    }));

    //update the title
    document.title = tenant.name;
  }

  /** update the tenants enabled on this user profile */
  updateTenants(tenants: Tenant[]) {
    tenantStore.update((existingState) => ({
      ...existingState,
      tenants,
    }));
  }
}

export interface TenantState {
  tenants: Tenant[];
  activeTenant: Tenant;
}
/** Tenant interface */
export interface Tenant {
  id: string;
  pcns: string;
  name: string;
  patientIndex: string;
  logo: string;
  msvCounter: number;
  patientCounter: number;
  claimCounter: number;
  switch: any;
  filePrefix: string;
  disciplineCode: string;
  timebased: boolean;
  pmb: boolean;
  website: string;
  address: string;
  phone: string;
  email: string;
  surgical: boolean;
  bank: string;
  accountNumber: string;
  branch: string;
  lastInvoice: string;
  lastAmount: number;
  dispensingLicense: string;
  signatureFileKey: string;
  signatureFileName: string;
}
