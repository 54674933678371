import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { StateService } from './services/state.service';
import { SupabaseService } from './services/supabase.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  userRoles: any[];
  constructor(
    private router: Router,
    private state: StateService,
    private supabase: SupabaseService
  ) {
    this.initializeApp();
  }

  async initializeApp() {
    this.state.getTenant();

    const profile = await this.supabase.getProfile();
    this.userRoles = profile.roles;
  }

  openlink(link: string) {
    this.router.navigateByUrl(link);
  }
}
