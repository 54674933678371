/* eslint-disable max-len*/

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, Router } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  MatDatepickerModule,
  MAT_DATEPICKER_SCROLL_STRATEGY_FACTORY_PROVIDER,
} from '@angular/material/datepicker';
import { NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import { HttpClientModule } from '@angular/common/http';
import { NgChartsModule } from 'ng2-charts';
import { NgApexchartsModule } from 'ng-apexcharts';
import { CubejsClientModule } from '@cubejs-client/ngx';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { environment } from 'src/environments/environment';

const cubejsOptions = {
  token: environment.cubejs.token,
  options: {
    apiUrl: environment.cubejs.url,
  },
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    MatTooltipModule,
    BrowserAnimationsModule,
    MatNativeDateModule,
    MatDialogModule,
    NgxMatNativeDateModule,
    MatDatepickerModule,
    ScrollingModule,
    HttpClientModule,
    NgChartsModule,
    NgApexchartsModule,
    CubejsClientModule.forRoot(cubejsOptions),
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    MAT_DATEPICKER_SCROLL_STRATEGY_FACTORY_PROVIDER,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
